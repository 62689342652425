import React, { useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { TextureLoader, CanvasTexture, RepeatWrapping } from 'three';
import { OrbitControls } from '@react-three/drei';
import { Box, FormControl, InputLabel, MenuItem, Select, Slider, Typography } from '@mui/material';

const Cup3D = ({ logo, estampa, cupSize, corLisa }) => {
  const [combinedTexture, setCombinedTexture] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCup, setSelectedCup] = useState(cupSize);
  const [logoScaleWidth, setLogoScaleWidth] = useState(0.2); // Valor inicial
  const [logoScaleHeight, setLogoScaleHeight] = useState(0.8); // Valor inicial
  const [logoPositionX, setLogoPositionX] = useState(0.5); // Posição X inicial (percentual)
  const [logoPositionY, setLogoPositionY] = useState(0.5); 

  const cupSizes = {
    "COPO-100": { topRadius: 0.4, bottomRadius: 0.3, height: 0.7, borderTopRadius: 0.43, borderBottomRadius: 0.43, borderHeight: 0.03 },
    "COPO-180": { topRadius: 0.6, bottomRadius: 0.45, height: 1.8, borderTopRadius: 0.63, borderBottomRadius: 0.63, borderHeight: 0.05 },
    "COPO-220": { topRadius: 0.65, bottomRadius: 0.55, height: 1.8, borderTopRadius: 0.7, borderBottomRadius: 0.7, borderHeight: 0.05 },
    "COPO-300": { topRadius: 0.8, bottomRadius: 0.6, height: 2.5, borderTopRadius: 0.83, borderBottomRadius: 0.83, borderHeight: 0.05 },
    "COPO-400": { topRadius: 0.9, bottomRadius: 0.7, height: 2.8, borderTopRadius: 0.93, borderBottomRadius: 0.93, borderHeight: 0.05 },
    "COPO-500": { topRadius: 1, bottomRadius: 0.7, height: 3, borderTopRadius: 1.03, borderBottomRadius: 1.03, borderHeight: 0.05 },
    "POTE-120": { topRadius: 0.7, bottomRadius: 0.57, height: 0.5, borderTopRadius: 0.72, borderBottomRadius: 0.72, borderHeight: 0.04 },
    "POTE-160": { topRadius: 0.75, bottomRadius: 0.65, height: 0.54, borderTopRadius: 0.78, borderBottomRadius: 0.78, borderHeight: 0.04 },
    "POTE-210": { topRadius: 0.8, bottomRadius: 0.62, height: 0.73, borderTopRadius: 0.83, borderBottomRadius: 0.83, borderHeight: 0.05 },
    "POTE-270": { topRadius: 1.0, bottomRadius: 0.9, height: 0.57, borderTopRadius: 1.03, borderBottomRadius: 1.03, borderHeight: 0.05 },
    "POTE-360": { topRadius: 1.0, bottomRadius: 0.8, height: 0.78, borderTopRadius: 1.03, borderBottomRadius: 1.03, borderHeight: 0.05 },
    "POTE-550": { topRadius: 1.25, bottomRadius: 1.02, height: 0.74, borderTopRadius: 1.28, borderBottomRadius: 1.28, borderHeight: 0.05 },
  };

  useEffect(() => {
    const loadTextures = async () => {
      const canvasResolution = 2048; // Resolução alta para qualidade
      const canvas = document.createElement('canvas');
      canvas.width = canvasResolution;
      canvas.height = canvasResolution;
      const context = canvas.getContext('2d');

      // Preencher com cor lisa ou estampa
      if (estampa) {
        const baseTexture = await new Promise((resolve) =>
          new TextureLoader().load(estampa, resolve)
        );
        context.drawImage(baseTexture.image, 0, 0, canvasResolution, canvasResolution);
      } else if (corLisa) {
        context.fillStyle = `rgb(${corLisa})`;
        context.fillRect(0, 0, canvasResolution, canvasResolution);
      }

      // Adicionar logo
      if (logo) {
        const logoTexture = await new Promise((resolve) =>
          new TextureLoader().load(logo, resolve)
        );
        const logoWidth = canvas.width * logoScaleWidth;
        const logoHeight = logoWidth * logoScaleHeight;
        
        const logoX = (canvas.width * logoPositionX) - (logoWidth / 2); // Posição baseada no slider
        const logoY = (canvas.height * logoPositionY) - (logoHeight / 2); 

        context.drawImage(logoTexture.image, logoX, logoY, logoWidth, logoHeight);
      }

      const combined = new CanvasTexture(canvas);
      combined.wrapS = RepeatWrapping;
      combined.wrapT = RepeatWrapping;
      setCombinedTexture(combined);
      setLoading(false);
    };

    loadTextures();
  }, [estampa, logo, corLisa, selectedCup, logoScaleWidth, logoScaleHeight, logoPositionX, logoPositionY]);

  if (loading) {
    return <p>Carregando...</p>;
  }

  const currentSize = cupSizes[selectedCup];

  return (
    <Box>
      <Typography variant="h6">Selecione o tipo do produto:</Typography>
      <FormControl fullWidth sx={{ mt: 2, minWidth: 200 }}>
        <InputLabel id="tipo-produto-label">Tipo do Produto</InputLabel>
        <Select
          labelId="tipo-produto-label"
          value={selectedCup}
          onChange={(e) => setSelectedCup(e.target.value)}
          label="Tipo do Produto"
        >
          {Object.keys(cupSizes).map((key) => (
            <MenuItem key={key} value={key}>
              {key.replace('-', ' ')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box
        width={{ xs: '99%', sm: '70vh' }}
        height={{ xs: '400px', sm: '400px' }}
        mt={4}
        style={{
          marginTop: '20px',
          border: '1px solid #000',
          borderRadius: '25px',
          backgroundColor: '#f5f5f5',
        }}
      >
        <Canvas>
          <OrbitControls 
            enableDamping
            dampingFactor={0.25}
            rotateSpeed={0.5} 
            minPolarAngle = {Math.PI / 4} // Ângulo mínimo (em radianos)
            maxPolarAngle = {Math.PI / 2} // Ângulo máximo (em radianos)
            minDistance = {selectedCup.includes("100") ? 1 : 2} // Distância mínima
            maxDistance = {10} // Distância máxima 
            />
          <ambientLight intensity={1.5} />
          <pointLight position={[10, 10, 10]} />

          <mesh rotation={[0, Math.PI, 0]}>
            <cylinderGeometry
              args={[
                currentSize.topRadius,
                currentSize.bottomRadius,
                currentSize.height,
                64,
              ]}
            />
            <meshStandardMaterial map={combinedTexture} side={2} />
          </mesh>
           {/* Fundo e topo */}
           <mesh position={[0, currentSize.height / 2 + currentSize.borderHeight / 2, 0]}>
            <cylinderGeometry args={[currentSize.borderTopRadius, currentSize.borderBottomRadius, currentSize.borderHeight, 64]} />
            <meshStandardMaterial color="white" />
          </mesh>
          <mesh position={[0, 0, 0]}>
            <cylinderGeometry args={[currentSize.topRadius - 0.05, currentSize.bottomRadius - 0.05, currentSize.height - 0.1, 64]} />
            <meshStandardMaterial color="white" opacity={0.5} transparent={true} />
          </mesh>
          <mesh position={[0, -currentSize.height / 1.99, 0]} rotation-x={Math.PI / 2}>
            <circleGeometry args={[currentSize.bottomRadius, 64]} />
            <meshStandardMaterial color="white" />
          </mesh>
        </Canvas>
      </Box>
      <FormControl fullWidth sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Posicionar a Logo
      </Typography>

      {/* Posição Horizontal */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body1">Mover Horizontalmente:</Typography>
        <Slider
          value={logoPositionX}
          min={0}
          max={1}
          step={0.01}
          color="primary"
          onChange={(e, value) => setLogoPositionX(value)}
        />
        <Typography variant="body2">{(logoPositionX * 100).toFixed(0)}%</Typography>
      </Box>

      {/* Posição Vertical */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
        <Typography variant="body1">Mover Verticalmente:</Typography>
        <Slider
          value={logoPositionY}
          min={0}
          max={1}
          step={0.01}
          color="primary"
          onChange={(e, value) => setLogoPositionY(value)}
        />
        <Typography variant="body2">{(logoPositionY * 100).toFixed(0)}%</Typography>
      </Box>
    </FormControl>

    <FormControl fullWidth sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Redimensionar a Logo
      </Typography>

      {/* Largura */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography variant="body1">Largura:</Typography>
        <Slider
          value={logoScaleWidth}
          min={0.1}
          max={1}
          step={0.01}
          color="secondary"
          onChange={(e, value) => setLogoScaleWidth(value)}
        />
        <Typography variant="body2">{(logoScaleWidth * 100).toFixed(0)}%</Typography>
      </Box>

      {/* Altura */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
        <Typography variant="body1">Altura:</Typography>
        <Slider
          value={logoScaleHeight}
          min={0.1}
          max={10}
          step={0.01}
          color="secondary"
          onChange={(e, value) => setLogoScaleHeight(value)}
        />
        <Typography variant="body2">{(logoScaleHeight * 10).toFixed(0)}%</Typography>
      </Box>
    </FormControl>
    </Box>
  );
};

export default Cup3D;
