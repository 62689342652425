import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { imagensDoceria } from "../Briefing/img/DOCERIA/estampasDoceria";
import { imagensDrink } from "../Briefing/img/DRINKS/drinksEstampas";
import { imagensPadaria } from "../Briefing/img/PADARIA/estampasPadaria";
import { imagensRestauranteDelivery } from "../Briefing/img/RESTAURANTE - DELIVERY - LANCHONETE/estampasRestauranteDelivery";
import { imagensMilkShake } from "../Briefing/img/MILK SHAKE/estampasMilkShake";
import { imagensClinica } from "../Briefing/img/CLÍNICA/estampasClinica";
import { imagensCafeteria } from "../Briefing/img/CAFETERIA/estampasCafeteria";
import { imagensEscritorio } from "../Briefing/img/ESCRITÓRIO/estampasEscritorio";
import { imagensAcai } from "../Briefing/img/AÇAÍ/estampasAcai";
import { imagensSorveteria } from "../Briefing/img/SORVETERIA/estampasSorveteria";
import { FileUploadOutlined } from "@mui/icons-material";
import config from "../../config/config";
import Cup3D500 from "./components/3dModels/Prototipo";
import ColorPicker from "../Briefing/components/ColorPicker";

const PrototipoForm = () => {
  const [formData, setFormData] = useState({});
  const [segmento, setSegmento] = useState(null);
  const [selectedEstampa, setSelectedEstampa] = useState(null);
  const [step, setStep] = useState(1);
  const [uploadedImage, setUploadedImage] = useState(null); // Para armazenar a URL temporária da imagem
  const [estampaBase64, setEstampaBase64] = useState(null);
  const [logoBase64, setLogoBase64] = useState(null);

  const handleCheckboxChangeEstampa = (value) => {
    setSelectedEstampa(value);
  };

  const convertImageToBase64 = async (imageUrl) => {
    const response = await fetch(imageUrl); // Busca a imagem pela URL
    const blob = await response.blob(); // Converte a resposta em um blob (arquivo binário)

    return new Promise((resolve, reject) => {
      const reader = new FileReader(); // Usa o FileReader para ler o blob como DataURL (base64)
      reader.onloadend = () => {
        resolve(reader.result); // Retorna o resultado em base64
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob); // Converte o blob em base64
    });
  };

  useEffect(() => {
    if (selectedEstampa) {
      convertImageToBase64(selectedEstampa).then((base64Image) => {
        setEstampaBase64(base64Image); // Armazena a estampa em base64
      });
    }
  }, [selectedEstampa]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result); // Armazena a URL temporária no estado
      };
      reader.readAsDataURL(file); // Lê o arquivo como DataURL
    }
  };

  useEffect(() => {
    if (uploadedImage) {
      setLogoBase64(uploadedImage); // Armazena a logo em base64
    }
  }, [uploadedImage]);

  const segmentos = {
    Doceria: {
      estampas: imagensDoceria,
    },
    Drinks: {
      estampas: imagensDrink,
    },
    Padaria: {
      estampas: imagensPadaria,
    },
    "Restaurante/Delivery": {
      estampas: imagensRestauranteDelivery,
    },
    "Milk Shake": {
      estampas: imagensMilkShake,
    },
    Clínica: {
      estampas: imagensClinica,
    },
    Cafeteria: {
      estampas: imagensCafeteria,
    },
    Escritório: {
      estampas: imagensEscritorio,
    },
    Açai: {
      estampas: imagensAcai,
    },
    Sorveteria: {
      estampas: imagensSorveteria,
    },
    Outros: {
      estampas: [],
    },
  };

  return (
    <div
    style={{
      background: "linear-gradient(to top left, white, #83a802)",
      minHeight: "100vh",
      maxWidth: "100vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Montserrat",
      padding: "10px",
    }}
  >
    <Box display="flex" flexDirection="column" alignContent="center" alignItems="center">
      <header>
          <img src="https://acdn.mitiendanube.com/stores/002/907/105/themes/common/logo-1336738559-1706047471-a90c2b04f7208c4f190adf866d8df0b51706047472-320-0.webp" alt="Logo Papello" />
      </header>
      {step === 1 && (
        <Box>
          <h1 style={{ textAlign: "center" }}>Olá, bem-vindo ao nosso formulário de protótipo 3D gratuito.</h1>
          <h1 style={{ textAlign: "center" }}>Como você deseja sua estampa?</h1>
          <Box display="flex" flexDirection="column" alignContent="center" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData["tipoEstampa"] === "Estampado"}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      ["tipoEstampa"]: e.target.checked ? "Estampado" : null,
                    }));
                  }}
                />
              }
            label="Estampado"
           />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData["tipoEstampa"] === "Cor Lisa"}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      ["tipoEstampa"]: e.target.checked ? "Cor Lisa" : null,
                    }));
                  }}
                />
              }
            label="Cor Lisa"
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Button color="success" variant="contained" disabled={!formData["tipoEstampa"]}
             onClick={() => setStep(2)}>Próximo</Button>
          </Box>
        </Box>

      )}
      {step === 2 && formData["tipoEstampa"] === "Estampado" && (
        <Box>
          <h1 style={{ textAlign: "center" }}>Selecione seu segmento:</h1>
          <Box>
            <FormControl fullWidth>
              <InputLabel id="segmento-label">Selecione seu segmento aqui</InputLabel>
              <Select
                label="Segmento"
                onChange={(e) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    Segmento: e.target.value,
                  }));
                  setSegmento(e.target.value);
                }}
                value={segmento}
              >
                {Object.keys(segmentos).map((key) => (
                  <MenuItem key={key} value={key}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box mt={2} display="flex" justifyContent="center">
              <Button variant="contained" color="success" disabled={!segmento}
              onClick={() => setStep(3)}>
                Próximo
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {step === 2 && formData["tipoEstampa"] === "Cor Lisa" && (
        <Box>
          <ColorPicker 
            formField="corLisa"
            formData={formData}
            setFormData={setFormData}
            step={step}
            setValue={(value) => setFormData((prevState) => ({ ...prevState, corLisa: value }))}
            setStep={setStep}
            showColorPicker={true}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button variant="contained" color="success" disabled={!formData["corLisa"]}
             onClick={() => setStep(4)}>
              Próximo
            </Button>
          </Box>
        </Box>
      )}

      {step === 3 && segmento && segmento !== "Outros" && (
        <Box>
          <h1 style={{ textAlign: "center" }}>Selecione uma das estampas</h1>
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)", // 1 coluna em telas pequenas
              sm: "repeat(2, 1fr)", // 2 colunas em telas médias
              md: "repeat(3, 1fr)", // 3 colunas em telas grandes
            }}
            gap="10px"
            width="100%"
            justifyContent="center"
            alignItems="center"
            justifyItems="center"
          >
            {segmentos[segmento]?.estampas?.map((image, index) => (
              <Card key={index} sx={{ width: "100%" }}>
                <CardActionArea
                  onClick={() => handleCheckboxChangeEstampa(`${config.url}${image.caminho}`)}
                >
                  <CardMedia
                    component="img"
                    height="250"
                    image={image.caminho}
                    alt="Estampa"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      {image.nome}
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedEstampa === `${config.url}${image.caminho}`}
                          onChange={() => handleCheckboxChangeEstampa(`${config.url}${image.caminho}`)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      }
                      label="Selecionar"
                    />
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
              <Button variant="contained" color="success" disabled={!selectedEstampa}
               onClick={() => setStep(4)}>
                Próximo
              </Button>
            </Box>
        </Box>
      )}

      {step === 4 && (
        <Box textAlign="center">
          <Typography variant="h6">Envie sua logo em formato PNG</Typography>
          <input
            style={{ display: "none" }}
            type="file"
            accept="image/png"
            onChange={handleFileChange}
            id="contained-button-file"
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              component="span"
              color="success"
              startIcon={<FileUploadOutlined />}
            >
              Envie sua logo aqui
            </Button>
          </label>
          {uploadedImage && (
            <Box mt={2} display="flex" flexDirection="column"alignContent="center" justifyContent="center" >
              <Box mt={2}>
                <Typography variant="h6">Pré-visualização da Logo:</Typography>
                <img
                  src={uploadedImage}
                  alt="Logo"
                  style={{ width: "100%", maxWidth: "300px" }}
                />
              </Box>
              <Box mt={2}>
                <Button color="success" variant="contained" disabled={!uploadedImage}
                onClick={() => setStep(5)}>Próximo</Button>
              </Box>
            </Box>
          )}

        </Box>
        
      )}
      {step === 5 && logoBase64 && (estampaBase64 || formData['corLisa']) && (
        <Box textAlign="center">
          
          <Typography variant="h6">Selecione o tipo do produto:</Typography>
          <FormControl fullWidth sx={{ mt: 2, minWidth: 200 }}>
            <InputLabel id="tipo-produto-label">Tipo do Produto</InputLabel>
            <Select
              labelId="tipo-produto-label"
              value={formData.tipoProduto || ""}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  tipoProduto: e.target.value,
                }))
              }
              label="Tipo do Produto"
            >
              <MenuItem value="COPO-100">Copo de 100ml</MenuItem>
              <MenuItem value="COPO-180">Copo de 180ml</MenuItem>
              <MenuItem value="COPO-220">Copo de 220ml</MenuItem>
              <MenuItem value="COPO-300">Copo de 300ml</MenuItem>
              <MenuItem value="COPO-400">Copo de 400ml</MenuItem>
              <MenuItem value="COPO-500">Copo de 500ml</MenuItem>
              <MenuItem value="POTE-120">Pote de 120ml</MenuItem>
              <MenuItem value="POTE-160">Pote de 160ml</MenuItem>
              <MenuItem value="POTE-210">Pote de 210ml</MenuItem>
              <MenuItem value="POTE-270">Pote de 270ml</MenuItem>
              <MenuItem value="POTE-360">Pote de 360ml</MenuItem>
              <MenuItem value="POTE-550">Pote de 550ml</MenuItem>
            </Select>
          </FormControl>
          <Box mt={2}>
            <Button variant="contained" color="success" disabled={!formData.tipoProduto}
            onClick={() => setStep(6)}>
              Próximo
            </Button>
          </Box>
        </Box>
      )}

{step === 6 && logoBase64 && (estampaBase64 || formData['corLisa']) && (
  <Box textAlign="center">
    <Box
      mt={2}
      mb={2}
      display="flex"
      alignContent="center"
      justifyContent="center"
      flexDirection={{ xs: "column", sm: "row" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{ flex: 1 }} // Faz as instruções ocuparem o espaço proporcionalmente
      >
        {window.innerWidth > 600 ? (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginRight={4}>
            <h1>Instruções para visualizar e ajustar o protótipo 3D:</h1>
            <Typography variant="body1" gutterBottom>
              <strong>Visualização do protótipo 3D:</strong>
            </Typography>
            <Typography>* Clique com o botão direito do mouse para rotacionar o protótipo 3D.</Typography>
            <Typography>* Clique com o botão esquerdo do mouse para movimentar o protótipo 3D.</Typography>
            <Typography>* Use a roda do mouse para dar zoom no protótipo 3D.</Typography>
            <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
              <strong>Ajuste da logo no protótipo:</strong>
            </Typography>
            <Typography>* Use os controles de posição (horizontal e vertical) para mover a logo.</Typography>
            <Typography>* Use os controles de largura e altura para redimensionar a logo.</Typography>
          </Box>
        ) : (
          <Box>
            <h1>Instruções para visualizar e ajustar o protótipo 3D:</h1>
            <Typography variant="body1" gutterBottom>
              <strong>Visualização do protótipo 3D:</strong>
            </Typography>
            <Typography>* Toque e arraste para rotacionar o protótipo 3D.</Typography>
            <Typography>* Toque com dois dedos e arraste para movimentar o protótipo 3D.</Typography>
            <Typography>* Use o gesto de pinça com dois dedos para dar zoom no protótipo 3D.</Typography>
            <Typography variant="body1" gutterBottom sx={{ mt: 2 }}>
              <strong>Ajuste da logo no protótipo:</strong>
            </Typography>
            <Typography>* Use os controles de posição (horizontal e vertical) para mover a logo.</Typography>
            <Typography>* Use os controles de largura e altura para redimensionar a logo.</Typography>
          </Box>
        )}
      </Box>
      <Box>
        <Cup3D500
          logo={logoBase64}
          estampa={estampaBase64}
          cupSize={formData.tipoProduto}
          corLisa={formData['corLisa']}
        />
      </Box>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        alignContent="center"
        justifyContent="center"
        sx={{
          mt: { xs: 3, sm: 2 }, // Ajusta o espaçamento para mobile
        }}
      >
      </Box>
    </Box>
    <Button
      variant="contained"
      onClick={() => {
        setFormData({});
        setSegmento(null);
        setSelectedEstampa(null);
        setEstampaBase64(null);
        setStep(1);
      }}
    >
      Voltar
    </Button>
  </Box>
)}

    </Box>
    </div>
  );
};

export default PrototipoForm;
