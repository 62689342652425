import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Box, Typography, TextField, Grid } from '@mui/material';

const ColorPicker = ({ register, setValue, setFormData, formField, formData, showColorPicker }) => {
  const [color, setColor] = useState('#a68282');
  const [rgb, setRgb] = useState('166,130,130');
  const [error, setError] = useState(false);

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const rgbToHex = (r, g, b) => `#${[r, g, b].map(x => x.toString(16).padStart(2, '0')).join('')}`;

  const handleHexChange = (newHex) => {
    setColor(newHex);
    const rgbColor = hexToRgb(newHex);
    setRgb(`${rgbColor.r},${rgbColor.g},${rgbColor.b}`);

    setValue(formField, `${rgbColor.r},${rgbColor.g},${rgbColor.b}`);
    setFormData((prev) => ({ ...prev, [formField]: `${rgbColor.r},${rgbColor.g},${rgbColor.b}` }));
  };

  const handleRgbChange = (newRgb) => {
    const regex = /^\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\s*$/;
    const match = newRgb.match(regex);

    if (match) {
      const [r, g, b] = match.slice(1, 4).map((val) => Math.min(255, Math.max(0, parseInt(val))));
      const newHex = rgbToHex(r, g, b);

      setError(false);
      setRgb(`${r},${g},${b}`);
      setColor(newHex);

      setValue(formField, `${r},${g},${b}`);
      setFormData((prev) => ({ ...prev, [formField]: `${r},${g},${b}` }));
    } else {
      setError(true);
    }
  };

  return showColorPicker ? (
<Grid container spacing={2}>
  {/* Color Picker */}
  <Grid item xs={12} sm={6}>
    <Box display="flex" justifyContent="center" alignItems="center">
      <HexColorPicker color={color} onChange={handleHexChange} />
    </Box>
  </Grid>

  {/* Color Preview */}
  <Grid item xs={12} sm={6}>
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        backgroundColor: color,
        width: '100%',
        maxWidth: '300px', // Limite para desktop
        height: '100px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        margin: '0 auto', // Centraliza em dispositivos menores
      }}
    />
  </Grid>

  {/* RGB Input */}
  <Grid item xs={12}>
    <Typography variant="subtitle1" textAlign={{ xs: 'center', sm: 'left' }}>
      RGB:
    </Typography>
    <TextField
      value={rgb}
      variant="outlined"
      fullWidth
      onChange={(e) => handleRgbChange(e.target.value)}
      error={error}
      helperText={error ? 'Formato inválido. Use: R,G,B (0-255).' : ''}
      sx={{
        mt: 1,
        maxWidth: { xs: '100%', sm: '400px' }, // Limite de largura para desktop
        margin: '0 auto', // Centraliza em dispositivos menores
      }}
    />
  </Grid>
</Grid>

  ) : (
    <Box mt={4} display="flex" flexDirection="column" alignItems="center" textAlign="center">
      <h2>Qual a cor que você deseja na sua embalagem?</h2>
      <Box container spacing={2} alignItems="center" padding="10px" mb={2} >
      <TextField
              label="Digite aqui as cores que você deseja para sua embalagem em formato CMYK."
              color="info"
              value={
                formData[formField]
              }
              width="100%"
              onChange={(e) => setFormData(prevState => ({ ...prevState, [formField]: e.target.value }))}
              sx={{
                width: {
                  xs: '100%', // Para telas muito pequenas (mobile)
                  sm: '400px', // Para telas pequenas (tablets)
                  md: '500px', // Para telas médias (desktops)
                  lg: '600px', // Para telas grandes
                  xl: '700px', // Para telas extragrandes
                },
                height: {
                  xs: '40px', // Altura para telas pequenas
                  sm: '50px', 
                  md: '60px', 
                  lg: '70px', 
                  xl: '80px',
                },
              }}
            />
            <Box marginTop="25px">
              <Typography>Forneça os códigos CMYK. Isso nos ajudará a corresponder às suas expectativas de design com precisão.<p style={{color: "red", fontWeight: "bold"}}>*Não trabalhamos com códigos PANTONE, RGB e HEX</p> </Typography>
            </Box>
      </Box>
    </Box>
  );
};

export default ColorPicker;